import { useNavigate } from "react-router-dom";
import { stringIsNullOrEmpty } from "../common/util";
import { useSelector } from "react-redux";
import { SessionKey, LocalStorageKey } from "../common/constant";
import { Pages, _ROUTES } from "../routes/page";

/// <summary>
/// Author: Saitama
/// </summary>
export function useRouter() {
    const _navigate = useNavigate();
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function navigate(url = '', state = {}, replace = false) {
        var targetRoute = _ROUTES.find(route => url == route.path);

        sessionStorage.removeItem(SessionKey._ROUTE_TO_PROCEED);

        if (!targetRoute) {
            _navigate(Pages._DEFAULT, { replace });
        }
        else if (targetRoute.auth && stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._BEARER_TOKEN))) {
            sessionStorage.setItem(SessionKey._ROUTE_TO_PROCEED, targetRoute.path);
        }
        else {
            _navigate(url, { replace, state });
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function back() {
        const previousLocation = Pages._DEFAULT;
        const currentHostname = window.location.hostname;
        const previousHostname = new URL(previousLocation, window.location.href).hostname;

        if (previousHostname !== currentHostname) {
            _navigate(Pages._DEFAULT);
        }
        else {
            _navigate(-1);
        }
    }

    return {
        navigate, back
    }
}
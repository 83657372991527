import { _WEB_VERSION } from './constant';
export const _BASE_URL = 'https://tip-api.peaceclubpenang.com'; // Live
// export const _BASE_URL = 'https://tip-user-api.mpw-test.com'; // Stage

const Routes = {
    _BRANCH_DETAIL: _BASE_URL + '/branch/detail',
    _MESSAGE_TEMPLATE: _BASE_URL + '/branch/message-template',
    _GIFT: _BASE_URL + '/branch/gift',
    _STAFF_CATEGORY: _BASE_URL + '/branch/staff/categories',
    _STAFF: _BASE_URL + '/branch/staff',
    _INIT_TRANSACTION: _BASE_URL + '/transaction/init-topup',
    _STAFF_SUBCATEGORY: _BASE_URL + '/branch/staff/sub-categories?parentId=',
};

export default Routes;

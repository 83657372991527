import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery, stringIsNullOrEmpty } from "../common/util";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import { useRouter } from '../hooks/useRouter';
import { Stack, Text, Box, Image } from '@chakra-ui/react';
import { LocalStorageKey, TippingType } from "../common/constant";
import { Pages } from "../routes/page";
import OrderConfirmationDialog from "../component/order-confirmation-dialog";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    var _queryStr = useQuery(_location);
    const { t } = useTranslation();
    const [showOrderDialog, setShowOrderDialog] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._BRANCH)) || stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._TABLE))) {
            _router.back();
        }
    }, [])

    return (
        <Stack spacing={10} w='full'>
            <Box p={10} bg='white' borderRadius={20} onClick={() => { _router.navigate(Pages._MESSAGE_DETAILS, { type: TippingType._PRESET }); }}>
                <Stack align='center'>
                    <Image boxSize={'70px'} src={require('../assets/images/preset_message.png')} />
                    <Text fontSize={'lg'} as='b'>{t('PRESET_MESSAGE')}</Text>
                </Stack>
            </Box>
            <Box p={10} bg='white' borderRadius={20} onClick={() => { setShowOrderDialog(true); }}>
                <Stack align='center'>
                    <Image boxSize={'70px'} src={require('../assets/images/custom_message.png')} />
                    <Text fontSize={'lg'} as='b'>{t('CUSTOM_MESSAGE')}</Text>
                </Stack>
            </Box>
            <OrderConfirmationDialog
                isVisible={showOrderDialog}
                onDialogClose={() => setShowOrderDialog(false)}
                title={'CUSTOM_MESSAGE'}
                type={TippingType._CUSTOM}
            />
        </Stack>
    )
}
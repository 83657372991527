import React, { useEffect, useState } from 'react';
import { Language, LocalStorageKey } from "./common/constant";
import { stringIsNullOrEmpty, isObjectEmpty } from "./common/util";
import Loader from "./component/loader";
import Content from "./routes/content";
import { connect, useDispatch, useSelector } from "react-redux";
import { useRouter } from "./hooks/useRouter";
import { PageSettings } from './routes/setting';
import CustomDialog from './component/dialog';
import LoginDialog from './component/login-dialog';
import { showCustomDialog, setTempCustomDialogData } from './application/action/app_action';
import { setBusy, setIdle } from './application/action/app_action';
import Header from './component/header';
import Footer from './component/footer';

/// <summary>
/// Author: Saitama
/// </summary>
const App = () => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _customDialogData = useSelector((state) => state.appState.customDialogData);
    var _tempCustomDialogData = useSelector((state) => state.appState.tempCustomDialogData);
    const showLoginDialog = useSelector((state) => state.appState.loginDialogVisible);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            if (stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._LANGUAGE))) {
                await localStorage.setItem(LocalStorageKey._LANGUAGE, Language._ENGLISH);
            }

            setIsFirstLoad(false);
        })();
    }, []);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isFirstLoad) {
            _dispatch(setBusy());
        }
        else {
            _dispatch(setIdle());
        }
    }, [isFirstLoad]);

    /// <summary>
    /// Author : Saitama
    /// </summary>
    useEffect(() => {
        if (
            isObjectEmpty(_customDialogData) &&
            !isObjectEmpty(_tempCustomDialogData)
        ) {
            _dispatch(showCustomDialog(_tempCustomDialogData));
            _dispatch(setTempCustomDialogData({}));
        }
    }, [_customDialogData, _tempCustomDialogData]);

    return (
        <PageSettings.Provider value={{}}>
            <Loader />
            {
                !isFirstLoad &&
                <>
                    <CustomDialog
                        isVisible={!isObjectEmpty(_customDialogData)}
                        success={_customDialogData?.success}
                        headerTitle={_customDialogData?.headerTitle}
                        title={_customDialogData?.title}
                        content={_customDialogData?.content}
                        customContent={_customDialogData?.customContent}
                        onConfirm={_customDialogData?.onConfirm}
                        onCancel={_customDialogData?.onCancel}
                        confirmTxt={_customDialogData?.confirmTxt}
                        headerIcon={_customDialogData?.headerIcon}
                        cancelTxt={_customDialogData?.cancelTxt}
                        horizontalBtn={_customDialogData?.horizontalBtn}
                        disableHardwareBackPress={
                            _customDialogData?.disableHardwareBackPress
                        }
                        isPlainTheme={_customDialogData?.isPlainTheme}
                        hideTitle={_customDialogData?.hideTitle}
                        hideFooter={_customDialogData?.hideFooter}
                    />
                    <LoginDialog isVisible={showLoginDialog} />
                    <Header />
                    <div className='app'>
                        <Content />
                    </div>
                    <Footer />
                </>
            }

        </PageSettings.Provider>
    );
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps, null)(App);
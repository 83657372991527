import React, { useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Stack, Text, Box, Spacer, Card, CardBody, CardFooter, Flex, Divider, Button } from '@chakra-ui/react';
import moment from "moment";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const { t } = useTranslation();

    return (
        <Box className="app-footer">
            <Text color={'white'} textAlign={'center'}>
                {`${t('COPYRIGHT')} © ${moment().format('YYYY')} TippingWorld ${t('ALL_RIGHT_RESERVED')}`}
            </Text>
        </Box>
    )
}
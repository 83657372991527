import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button,
    Stack,
    Divider,
    Text
} from '@chakra-ui/react';
import { disposeCustomDialog } from "../application/action/app_action";
import { stringIsNullOrEmpty } from "../common/util";
import { _DEBOUNCE_DURATION_MS } from "../common/constant";
import moment from 'moment';

/// <summary>
/// Author: Saitama
/// </summary>
const CustomDialog = ({
    isVisible,
    success,
    headerTitle,
    title,
    content,
    onConfirm,
    onCancel,
    headerIcon,
    footerContent,
    confirmTxt = 'OK',
    customContent,
    hideTitle = false,
    hideFooter = false,
    cancelTxt,
    isManualHideAlert = false,
    horizontalBtn = true,
    disableHardwareBackPress = false,
    isPlainTheme = false
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();
    const [buttonClickTime, setButtonClickTime] = useState(null);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isVisible) {
            onOpen();
        }
        else {
            closeDialogHandler();
        }
    }, [isVisible]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function closeDialogHandler() {
        onClose();
        dispatch(disposeCustomDialog());
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function canelHandler() {
        if (onCancel) {
            onCancel();
        }

        closeDialogHandler();
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const confirmHandler = () => {
        let currentTime = moment();

        if (
            buttonClickTime == null ||
            currentTime.diff(moment(buttonClickTime)) > _DEBOUNCE_DURATION_MS
        ) {
            setButtonClickTime(currentTime);

            if (onConfirm) {
                onConfirm();
            }

            if (!isManualHideAlert) {
                closeDialogHandler();
            }
        }
    };

    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={() => {
                closeDialogHandler();
            }}
            isOpen={isOpen}
            isCentered
            closeOnOverlayClick={disableHardwareBackPress}
            size={'xs'}
        >
            <AlertDialogOverlay />
            <AlertDialogContent className="app-modal-content">
                <AlertDialogHeader className="app-modal-header">
                    {
                        headerTitle &&
                        <Text fontSize='xl' as='b'>{t(headerTitle)}</Text>
                    }
                    {
                        !hideTitle && (!stringIsNullOrEmpty(title) ||
                            stringIsNullOrEmpty(headerTitle)) &&
                        <Text fontSize='xl' as='b'>
                            {
                                !stringIsNullOrEmpty(title) ? t(title) :
                                    success ? t('SUCCESS') : t('OOPS')
                            }
                        </Text>
                    }
                </AlertDialogHeader>
                <Divider />
                <AlertDialogBody className="app-modal-body">
                    {
                        !isPlainTheme &&
                        <Text fontSize='md'>
                            {
                                !stringIsNullOrEmpty(content) ? t(content) :
                                    success ? t('OPERATION_SUCCESS') : t('INTERNAL_SERVER_ERROR')
                            }
                        </Text>
                    }
                    {customContent && <div style={{ marginTop: '0.3rem' }}>{customContent}</div>}
                </AlertDialogBody>
                {
                    !hideFooter &&
                    <AlertDialogFooter className="app-modal-footer">
                        <Stack direction={horizontalBtn ? 'column' : 'row'} w='100%' gap={5}>
                            {
                                onCancel &&
                                <Button
                                    w='100%'
                                    className="modal-cancel-button"
                                    ref={cancelRef}
                                    onClick={() => canelHandler()}>
                                    {cancelTxt
                                        ? t(cancelTxt)
                                        : t('CANCEL')}
                                </Button>
                            }
                            <Button
                                onClick={() => confirmHandler()}
                                w='100%'
                                bg={'green'}
                                color={'white'}
                                className="modal-ok-button"
                                style={{ borderBottomLeftRadius: onCancel ? 0 : 11, borderBottomLeftRadius: horizontalBtn ? 11 : 0 }}>
                                {t(confirmTxt)}
                            </Button>
                        </Stack>
                    </AlertDialogFooter>
                }
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default CustomDialog;
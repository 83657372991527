import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import i18n from "./localization/i18n";
import App from "./App";
import store from "./application/store";
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/index.css";

let persistor = persistStore(store);

const theme = extendTheme({
  components: {
    Text: {
      baseStyle: {
        fontSize: 'sm'
      },
    },
  },
})

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Text, Box, Image } from '@chakra-ui/react';
import { showLoginDialog } from "../application/action/app_action";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);

    return (
        <Stack spacing={10} w='full'>
            <Box p={10} bg='white' borderRadius={20} onClick={() => {
                if (_IS_LOGGED_IN) {

                }
                else {
                    _dispatch(showLoginDialog());
                }
            }}>
                <Stack align='center'>
                    <Image boxSize={'70px'} src={require('../assets/images/member.png')} />
                    <Text fontSize={'lg'} as='b'>{t('MEMBER')}</Text>
                </Stack>
            </Box>
            <Box p={10} bg='white' borderRadius={20} onClick={() => { }}>
                <Stack align='center'>
                    <Image boxSize={'70px'} src={require('../assets/images/scan-qr.png')} />
                    <Text fontSize={'lg'} as='b'>{t('SCAN_QR')}</Text>
                </Stack>
            </Box>
        </Stack>
    )
}
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery, stringIsNullOrEmpty, createMultiPartFormBody } from "../common/util";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import { useRouter } from '../hooks/useRouter';
import { Stack, Text, Box, Image } from '@chakra-ui/react';
import { LocalStorageKey, TippingType, ApiKey, PaymentGatewayType, _PAYMENT_VIA_H5 } from "../common/constant";
import { Pages } from "../routes/page";
import ApiEngine from "../common/api_engine";
import Routes from "../common/api_routes";
import { showCustomDialog } from "../application/action/app_action";

/// <summary>
/// Author: CK
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    var _queryStr = useQuery(_location);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(_queryStr?.get('branch'))) {
            localStorage.setItem(LocalStorageKey._BRANCH, _queryStr?.get('branch'));
        }

        if (!stringIsNullOrEmpty(_queryStr?.get('table'))) {
            localStorage.setItem(LocalStorageKey._TABLE, _queryStr?.get('table'));
        }

        submit();
    }, [])

    async function submit() {
        try {
            let data = {
                typeId: 2,
                paymentGatewayTypeId: PaymentGatewayType._SUREPAY,
                message: "Happy Birthday SurePay",
                branchGiftId: 6,
                songName: "",
                deviceType: _PAYMENT_VIA_H5,
                nickname: "SUREPAY"
            };

            let isDuitNow = PaymentGatewayType._DUIT_NOW == data.paymentGatewayTypeId;

            var responseJson = await ApiEngine.post(Routes._INIT_TRANSACTION, createMultiPartFormBody(data));

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _router.navigate(Pages._PAYMENT_GATEWAY, {
                    paymentGatewayTransaction: responseJson[ApiKey._API_DATA_KEY],
                    isDuitNow: isDuitNow,
                    paymentGatewayTypeId: data.paymentGatewayTypeId
                });
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <Stack spacing={10} w='full'>

        </Stack>
    )
}
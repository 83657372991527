import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRouter } from "../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Image,
    Center,
    Text,
    Stack,
    Flex,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    SimpleGrid,
    Spacer
} from '@chakra-ui/react';
import { LocalStorageKey, _LANGUAGE_OPTION, Language, ApiKey } from "../common/constant";
import { isObjectEmpty, useQuery, stringIsNullOrEmpty } from "../common/util";
import ApiEngine from "../common/api_engine";
import Routes from "../common/api_routes";
import { useLocation } from "react-router";
import { _ROUTES } from "../routes/page";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    const { t, i18n } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    var _location = useLocation();
    var _queryStr = useQuery(_location);
    var _router = useRouter();
    const [branchDetail, setBranchDetail] = useState();
    const [showBackButton, setShowBackButton] = useState(false);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(_queryStr?.get('branch'))) {
            localStorage.setItem(LocalStorageKey._BRANCH, _queryStr?.get('branch'));
        }

        if (!stringIsNullOrEmpty(_queryStr?.get('table'))) {
            localStorage.setItem(LocalStorageKey._TABLE, _queryStr?.get('table'));
        }

        getBranchDetail();

        if (_ROUTES.find(r => r.path == _location.pathname)?.backButton) {
            setShowBackButton(true);
        }
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (_ROUTES.find(r => r.path == _location.pathname)?.backButton) {
            setShowBackButton(true);
        }
        else {
            setShowBackButton(false);
        }
    }, [_location])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getBranchDetail() {
        try {
            var responseJson = await ApiEngine.get(Routes._BRANCH_DETAIL);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setBranchDetail(responseJson[ApiKey._API_DATA_KEY]);
            }
        }
        catch (err) {

        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function onChangeLanguage(language) {
        i18n.changeLanguage(language);
        localStorage.setItem(LocalStorageKey._LANGUAGE, language);
    }

    return (
        <Box className="app-header">
            <Flex>
                {/* <Text
                    color={'white'}
                    textDecoration={'underline'}
                    fontStyle={'italic'}>
                    {isObjectEmpty(_USER_DATA) ? t('GUEST') : _USER_DATA?.username}
                </Text> */}
                {
                    showBackButton &&
                    <Button variant={'link'} onClick={() => { _router.back(); }}>
                        <Image
                            boxSize={'33px'}
                            src={require(`../assets/images/back.png`)} />
                    </Button>
                }
                <Spacer />
                <Popover placement='left-start'>
                    {({ onClose }) => (
                        <>
                            <PopoverTrigger>
                                <Button variant={'link'} justifyContent={'flex-end'} onClick={() => { }}>
                                    <Image
                                        boxSize={'20px'}
                                        src={require(`../assets/images/${localStorage.getItem(LocalStorageKey._LANGUAGE)}.png`)} />
                                    <Text fontSize={'md'} ml={2} mr={1}>
                                        {_LANGUAGE_OPTION.find(l => l.value == localStorage.getItem(LocalStorageKey._LANGUAGE)).prefix}
                                    </Text>
                                    <Image
                                        boxSize={'12px'}
                                        src={require(`../assets/images/arrow-down.png`)} />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent w='45vw'>
                                <PopoverArrow />
                                {
                                    _LANGUAGE_OPTION.map((item, index) => {
                                        return (
                                            <PopoverHeader key={index} onClick={() => {
                                                onChangeLanguage(item.value);
                                                onClose();
                                            }}>
                                                <Flex>
                                                    <Image
                                                        boxSize={'25px'}
                                                        mr={5}
                                                        opacity={localStorage.getItem(LocalStorageKey._LANGUAGE) == item.value ? 1 : 0}
                                                        src={require(`../assets/images/tick.png`)} />
                                                    <SimpleGrid columns={3} spacing={1}>
                                                        <Image
                                                            boxSize={'25px'}
                                                            src={require(`../assets/images/${item.value}.png`)} />
                                                        <Text textAlign={'right'}>{t(item.prefix)}</Text>
                                                    </SimpleGrid>
                                                </Flex>
                                            </PopoverHeader>
                                        )
                                    })
                                }
                            </PopoverContent>
                        </>
                    )}
                </Popover>
            </Flex>
            <Center onClick={() => {
                if (!stringIsNullOrEmpty(branchDetail?.description)) {
                    window.open(branchDetail.description, '_blank').focus();
                }
            }}>
                <Stack alignItems={'center'}>
                    <Image boxSize={'100px'} objectFit='cover' src={branchDetail?.logo ?? require('../assets/images/logo512.png')} />
                    <Text color='white' fontSize={'lg'} textAlign='center' fontWeight={'bold'}>{branchDetail?.name ?? 'Scan QR to proceed'}</Text>
                </Stack>
            </Center>
        </Box>
    )
}
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Stack,
    Divider,
    Text
} from '@chakra-ui/react'
import { disposeLoginDialog } from "../application/action/app_action";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ isVisible }) => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isVisible) {
            onOpen();
        }
        else {
            closeDialogHandler();
        }
    }, [isVisible]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function closeDialogHandler() {
        onClose();
        _dispatch(disposeLoginDialog());
    }

    return (
        <Modal
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            isCentered
            closeOnOverlayClick={false}
            onClose={() => {
                closeDialogHandler();
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create your account</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>

                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='green' mr={3}>
                        {t('LOGIN')}
                    </Button>
                    <Button ref={cancelRef} onClick={() => closeDialogHandler()}>{t('CANCEL')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
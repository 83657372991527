import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Stack,
    Text,
    Input,
    Grid,
    Divider,
    Center,
    Box,
    Image,
    Textarea,
    Avatar,
    NumberInput,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon
} from '@chakra-ui/react'
import ApiEngine from "../common/api_engine";
import { ApiKey, TippingType, PaymentGatewayType, StaffCategory, _PAYMENT_VIA_H5 } from "../common/constant";
import Routes from "../common/api_routes";
import { showCustomDialog } from "../application/action/app_action";
import { stringIsNullOrEmpty, createMultiPartFormBody, numberWithCurrencyFormat, isObjectEmpty } from "../common/util";
import Dropzone from 'react-dropzone';
import { Pages } from "../routes/page";
import { useRouter } from "../hooks/useRouter";

/// <summary>
/// Author: Saitama
/// </summary>
export default ({ isVisible, onDialogClose, title, type, data }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();
    const [messageTemplate, setMessageTemplate] = useState({});
    const [message, setMessage] = useState([]);
    const [nameInput, setNameInput] = useState('');
    const [selectedGift, setSelectedGift] = useState();
    const [logoFile, setLogoFile] = useState();
    const [logoImage, setLogoImage] = useState();
    const [selectedStaff, setSelectedStaff] = useState();
    const _PAYMENT_METHOD = [
        {
            paymentType: PaymentGatewayType._SUREPAY,
            image: require('./../assets/images/fpx.png'),
            title: 'FPX'
        },
        {
            paymentType: PaymentGatewayType._DUIT_NOW,
            image: require('./../assets/images/duitnow.png'),
            title: 'DuitNow'
        }
    ];
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [songName, setSongName] = useState('');
    const [nickname, setNickname] = useState('');
    const [customAmount, setCustomAmount] = useState(0);
    const _MIN_TIPPING_AMOUNT = 50;
    const _PRESET_TIPPING_AMOUNT = 0;
    const _CUSTOM_TIPPING_AMOUNT = 1
    const [expandIndex, setExpandIndex] = useState(_PRESET_TIPPING_AMOUNT);
    const [previewStaff, setPreviewStaff] = useState({});

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (isVisible) {
            onOpen();
            getTippingInfo();
        }
        else {
            closeDialogHandler();
        }
    }, [isVisible]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function closeDialogHandler() {
        onClose();
        setLogoFile();
        setLogoImage();
        setNickname();
        setNameInput();
        setSongName();
        setExpandIndex(_PRESET_TIPPING_AMOUNT);
        setCustomAmount();

        if (onDialogClose) {
            onDialogClose();
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getTippingInfo() {
        try {
            var responseJson = await ApiEngine.get(type == TippingType._PRESET ? `${Routes._MESSAGE_TEMPLATE}/${data.id}` : type == TippingType._CUSTOM ? `${Routes._GIFT}?customMessage=true` : `${Routes._GIFT}?staffTipping=true`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            if (type == TippingType._PRESET) {
                setMessageTemplate(responseJson[ApiKey._API_DATA_KEY]);
                setMessage(responseJson[ApiKey._API_DATA_KEY]['content'].split('[NAME]'));
                setSelectedGift(responseJson[ApiKey._API_DATA_KEY]['gifts'].reduce((prev, curr) => prev.amount < curr.amount ? prev : curr));
            }
            else {
                let customMessageTemplate = {
                    gifts: [...responseJson[ApiKey._API_DATA_KEY]]
                };

                setSelectedGift(customMessageTemplate['gifts'].reduce((prev, curr) => prev.amount < curr.amount ? prev : curr));
                setMessageTemplate(customMessageTemplate);

                if (type == TippingType._TIPPING && data.length > 0) {
                    setSelectedStaff(data[0]);
                }
            }

            setSelectedPaymentMethod(_PAYMENT_METHOD[0]);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function submitConfirmation() {
        try {
            if (type != TippingType._TIPPING && stringIsNullOrEmpty(nameInput)) {
                if (type == TippingType._PRESET) {
                    throw 'PLEASE_ENTER_NAME';
                }
                else {
                    throw 'PLEASE_ENTER_MESSAGE';
                }
            }

            let data = {
                typeId: type,
                paymentGatewayTypeId: PaymentGatewayType._SUREPAY,
                message: type == TippingType._PRESET ? messageTemplate.content.replace('[NAME]', nameInput) : nameInput,
                branchGiftId: selectedGift.id,
                songName: songName,
                deviceType: _PAYMENT_VIA_H5,
                nickname: nickname
            };

            if (type == TippingType._TIPPING) {
                if (expandIndex == _CUSTOM_TIPPING_AMOUNT) {
                    if (stringIsNullOrEmpty(customAmount) || customAmount < _MIN_TIPPING_AMOUNT) {
                        throw t("INVALID_CUSTOM_AMOUNT", { min: _MIN_TIPPING_AMOUNT });
                    }
                    else {
                        delete data['branchGiftId'];
                        data['amount'] = customAmount;
                    }
                }

                data['receiverStaffId'] = selectedStaff.id;
            }

            if (logoFile) {
                data['image'] = logoFile;
            }

            _dispatch(showCustomDialog({
                headerTitle: t('PAYMENT_CONFIRMATION'),
                isPlainTheme: true,
                horizontalBtn: false,
                customContent: (
                    <div>
                        {
                            type == TippingType._TIPPING ?
                                <>
                                    <p>{t('TO')}: <span style={{ fontWeight: 'bold' }}>{selectedStaff.name}</span></p>
                                    <p>{t('AMOUNT')}: <span style={{ fontWeight: 'bold' }}>RM {expandIndex == _CUSTOM_TIPPING_AMOUNT ? customAmount : selectedGift.amount}</span></p>
                                </> :
                                <>
                                    <p>{t('MESSAGE')}: <span style={{ fontWeight: 'bold' }}>{type == TippingType._PRESET ? messageTemplate.content.replace('[NAME]', nameInput) : nameInput}</span></p>
                                    <p>{t('AMOUNT')}: <span style={{ fontWeight: 'bold' }}>RM {selectedGift.amount}</span></p>
                                    <p>{t('DISPLAY_TIME')}: <span style={{ fontWeight: 'bold' }}>{selectedGift?.displayTime} {t('SECONDS')}</span></p>
                                    <p><span style={{ fontWeight: 'bold' }}>{selectedGift?.displayRemark}</span></p>
                                    {
                                        !stringIsNullOrEmpty(logoImage) &&
                                        <p>{t('IMAGE')}: <Image boxSize={'50px'} src={logoImage} /></p>
                                    }
                                </>
                        }
                        <p>{t('SEND_BY')}: <span style={{ fontWeight: 'bold' }}>{nickname}</span></p>
                    </div>
                ),
                confirmTxt: t('PAY'),
                onCancel: () => { },
                onConfirm: () => { onSubmit(data); }
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function onSubmit(data) {
        try {
            let isDuitNow = PaymentGatewayType._DUIT_NOW == data.paymentGatewayTypeId;

            var responseJson = await ApiEngine.post(Routes._INIT_TRANSACTION, createMultiPartFormBody(data));

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _router.navigate(Pages._PAYMENT_GATEWAY, {
                    paymentGatewayTransaction: responseJson[ApiKey._API_DATA_KEY],
                    isDuitNow: isDuitNow,
                    paymentGatewayTypeId: data.paymentGatewayTypeId
                });
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            isCentered
            onClose={() => {
                closeDialogHandler();
            }}
            size={'sm'}
        >
            <ModalOverlay />
            <ModalContent bg={'whiteAlpha.900'}>
                <ModalHeader>{t(title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack>
                        {
                            type == TippingType._PRESET ?
                                message.map((item, index) => {
                                    return (
                                        <>
                                            <Text fontWeight={'750'} fontSize={'md'}>{item}</Text>
                                            {
                                                index == 0 &&
                                                <Input onInput={(e) => setNameInput(e.target.value)} />
                                            }
                                        </>
                                    )
                                }) : type == TippingType._CUSTOM ?
                                    <Textarea placeholder={t('MESSAGE_HERE')} onInput={(e) => setNameInput(e.target.value)} /> :
                                    <>
                                        <Text fontWeight={'900'}>{t('TO')} : </Text>
                                        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                                            {
                                                data.map((item, index) => {
                                                    return (
                                                        <Stack align={'center'}>
                                                            {
                                                                type == TippingType._TIPPING &&
                                                                <Avatar
                                                                    size={'lg'}
                                                                    src={stringIsNullOrEmpty(item.image) ? 'https://bit.ly/broken-link' : item.image}
                                                                    onClick={() => { setPreviewStaff(item); }} />
                                                            }
                                                            <Box key={index} w='100%' className={selectedStaff?.id == item.id ? 'gradient-button' : ''} onClick={() => { setSelectedStaff(item) }}>
                                                                <Button w='100%' bg={'black'} _hover={{ bg: 'black' }}>
                                                                    <Text color={'white'} fontWeight={'750'}>{item.name}</Text>
                                                                </Button>
                                                            </Box>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        <Modal isCentered isOpen={!isObjectEmpty(previewStaff)} onClose={() => setPreviewStaff({})}>
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader>{previewStaff?.name}</ModalHeader>
                                                <ModalCloseButton />
                                                <ModalBody alignSelf={'center'}>
                                                    <Image
                                                        boxSize={'350px'}
                                                        objectFit={'cover'}
                                                        borderRadius='full'
                                                        src={stringIsNullOrEmpty(previewStaff?.image) ? 'https://bit.ly/broken-link' : previewStaff?.image} />
                                                </ModalBody>

                                                <ModalFooter>
                                                    <Button colorScheme='red' mr={3} onClick={() => setPreviewStaff({})}>
                                                        Close
                                                    </Button>
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                    </>
                        }
                        {
                            type != TippingType._TIPPING &&
                            <Divider />
                        }
                        {
                            type == TippingType._TIPPING ?
                                <>
                                    <Text fontWeight={'900'}>{t('AMOUNT')} : </Text>
                                    <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                                        {
                                            messageTemplate?.gifts?.sort((a, b) => a.amount - b.amount)?.map((item, index) => {
                                                return (
                                                    <Box className={selectedGift?.id == item.id ? 'gradient-button' : ''} key={index} w='100%'>
                                                        <Button w='100%' bg={'black'} onClick={() => setSelectedGift(item)} _hover={{ bg: 'black' }}>
                                                            <Text color={'white'} fontWeight={'750'}>{item.amount}</Text>
                                                        </Button>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                    {/* <Accordion defaultIndex={_PRESET_TIPPING_AMOUNT} onChange={(value) => { setExpandIndex(value); }}>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={750}>
                                                        {t('AMOUNT')}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                                                    {
                                                        messageTemplate?.gifts?.sort((a, b) => a.amount - b.amount)?.map((item, index) => {
                                                            return (
                                                                <Box className={selectedGift?.id == item.id ? 'gradient-button' : ''} key={index} w='100%'>
                                                                    <Button w='100%' bg={'black'} onClick={() => setSelectedGift(item)} _hover={{ bg: 'black' }}>
                                                                        <Text color={'white'} fontWeight={'750'}>{item.amount}</Text>
                                                                    </Button>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </AccordionPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={750}>
                                                        {t('CUSTOM_AMOUNT')}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <NumberInput
                                                    onChange={(valueString) => setCustomAmount(valueString)} />
                                                <Input onInput={(e) => setCustomAmount(e.target.value)} placeholder={t('MIN_CUSTOM_AMOUNT', { min: numberWithCurrencyFormat(_MIN_TIPPING_AMOUNT) })} />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion> */}
                                </> :
                                <>
                                    <Text fontWeight={'900'}>{t('AMOUNT')} : </Text>
                                    <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                                        {
                                            messageTemplate?.gifts?.sort((a, b) => a.amount - b.amount)?.map((item, index) => {
                                                return (
                                                    <Box className={selectedGift?.id == item.id ? 'gradient-button' : ''} key={index} w='100%'>
                                                        <Button w='100%' bg={'black'} onClick={() => setSelectedGift(item)} _hover={{ bg: 'black' }}>
                                                            <Text color={'white'} fontWeight={'750'}>{item.amount}</Text>
                                                        </Button>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                </>
                        }
                        {
                            type != TippingType._TIPPING &&
                            <Divider />
                        }
                        <Text fontWeight={'900'}>{t('SEND_BY')} : </Text>
                        <Input onInput={(e) => setNickname(e.target.value)} placeholder={t('NICKNAME')} />
                        <Divider />
                        {
                            type != TippingType._TIPPING &&
                            <>
                                <Text fontSize={'md'}>{t('DISPLAY_TIME')} : <Text as={'span'} fontSize={'md'} fontWeight={'bold'} fontStyle={'italic'}>{selectedGift?.displayTime}</Text> {t('SECONDS')}</Text>
                                <Text fontSize={'md'} as={'i'}>{selectedGift?.displayRemark}</Text>
                            </>
                        }
                        {
                            selectedGift?.uploadImage &&
                            <>
                                <Box style={{ borderStyle: 'dashed' }} borderColor={'black'} borderWidth={0.5}>
                                    <Center>
                                        <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div className="dropzone" {...getRootProps()} style={{ paddingBlock: '2rem' }}>
                                                        <input {...getInputProps()} />
                                                        {stringIsNullOrEmpty(logoImage) && <Image boxSize={'50px'} src={require('./../assets/images/camera.png')} />}
                                                        {!stringIsNullOrEmpty(logoImage) && <aside className="thumbsContainer">
                                                            <div className="thumb">
                                                                <div className="thumbInner">
                                                                    <img
                                                                        src={logoImage}
                                                                        className="dropzone-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </aside>}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Center>
                                </Box>
                                {
                                    !stringIsNullOrEmpty(logoImage) &&
                                    <Button bg={'red'} color={'white'}
                                        onClick={() => {
                                            setLogoImage("");
                                            setLogoFile();
                                        }}>
                                        {t("REMOVE_IMAGE")}
                                    </Button>
                                }
                            </>
                        }
                        {/* {
                            type == TippingType._TIPPING && data.length > 0 && data[0]['category'] == StaffCategory._BAND &&
                            <>
                                <Divider />
                                <Text fontWeight={'900'}>{t('PICK_A_SONG')}? : </Text>
                                <Input onInput={(e) => setSongName(e.target.value)} />
                            </>
                        } */}
                        {/* <Divider /> */}
                        {/* <Text fontWeight={'900'}>{t('PAYMENT_METHOD')} : </Text> */}
                        {/* <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                            {
                                _PAYMENT_METHOD.map((item, index) => {
                                    return (
                                        <Stack>
                                            <Box className={selectedPaymentMethod?.paymentType == item.paymentType ? 'gradient-button' : ''} key={index} w='100%'>
                                                <Button w='100%' onClick={() => setSelectedPaymentMethod(item)}>
                                                    <Image boxSize={'30px'} src={item.image} />
                                                </Button>
                                            </Box>
                                            <Text fontWeight={'bold'} fontSize={'md'} textAlign={'center'}>{item.title}</Text>
                                        </Stack>
                                    )
                                })
                            }
                        </Grid> */}
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={() => submitConfirmation()}>
                        {t('PROCEED')}
                    </Button>
                    <Button ref={cancelRef} onClick={() => closeDialogHandler()}>{t('CANCEL')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
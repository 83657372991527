export const _DEBOUNCE_DURATION_MS = 600;
export const _PAYMENT_VIA_H5 = 1;

/// <summary>
/// Author: Saitama
/// </summary>
export class SessionKey {
  static _ROUTE_TO_PROCEED = "routeToProceed";
  static _BOTTOM_MENU_ITEM = "bottomMenuItem";
}

/// <summary>
/// Author: Saitama
/// </summary>
export class LocalStorageKey {
  static _LANGUAGE = "language";
  static _PREFERENCE_LANGUAGE = "preferenceLanguage";
  static _BEARER_TOKEN = "token";
  static _USER_DATA = "userData";
  static _BRANCH = "branch";
  static _TABLE = "table";
}

/// <summary>
/// Author: Saitama
/// </summary>
export class ApiKey {
  static _API_MESSAGE_KEY = 'message';
  static _API_DATA_KEY = 'data';
  static _API_STATUS_KEY = 'status';
  static _API_SUCCESS_KEY = 'success';
  static _API_REQUEST_KEY = 'request';
  static _API_RESPONSE_URL_KEY = 'responseURL';
  static _API_CODE_KEY = 'code';
  static _API_ERROR_KEY = 'error';
  static _API_TOTAL_AMOUNT_KEY = 'totalAmount';
  static _API_COUNT_KEY = 'count';

  static _API_CONTENT_TYPE = 'content-type';
  static _API_ACCEPT = 'Accept';
  static _API_APPLICATION_JSON = 'application/json';
  static _API_XML_HTTP_REQUEST_KEY = 'XMLHttpRequest';
  static _API_FORM_URLENCODED = 'application/x-www-form-urlencoded';
  static _API_POST = 'POST';
  static _API_GET = 'GET';

  static _API_STATUS_OK = 200;
  static _API_APP_VERSION_NOT_COMPATIBLE = 406;
  static _API_INVALID_AUTHORIZATION = 203;
  static _API_STATUS_UNAUTHORIZED = 401;
}

/// <summary>
/// Author: CK
/// </summary>
export class InputValidationKey {
  static _REQUIRED_KEY = 'required';
  static _MAX_LENGTH_KEY = 'maxLength';
  static _MIN_LENGTH_KEY = 'minLength';
  static _MAX_KEY = 'max';
  static _MIN_KEY = 'min';
  static _PATTERN = 'pattern';
}

/// <summary>
/// Author : CK
/// </summary>
export class Language {
  static _CHINESE = 'zh';
  static _ENGLISH = 'en';
  static _BAHASA_MELAYU = 'ms';
}

/// <summary>
/// Author : CK
/// </summary>
export class ValidationPattern {
  static _EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static _PHONE_NUMBER_REGEX = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
  static _NUMBER_REGEX = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
  static _DECIMAL_REGEX = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
  static _DIGIT_REGEX = /^\d+$/;
  static _ALPHABETIC_REGEX = /^[a-zA-Z ]+$/;
  static _STANDARD_REGEX = /^[A-Za-z0-9!@#$%^&*()_+}{":?><';,.~ /`|\\]*$/;
  static _IC_NUMBER_REGEX =
    /^(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})$/;
  static _PASSPORT_NUMBER_REGEX = /^[A-Za-z]{1,2}[0-9]{6,9}[A-Za-z]{0,1}$/;
  static _ALPHANUMBERIC_REGEX = /^[0-9a-zA-Z ]+$/;
  static _SPECIAL_CHARACTER_REGEX =
    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
}

/// <summary>
/// Author: CK
/// </summary>
export const _LANGUAGE_OPTION = [
  {
    label: 'English',
    prefix: 'EN',
    value: Language._ENGLISH
  },
  {
    label: '简体中文',
    prefix: '中文',
    value: Language._CHINESE
  },
  {
    label: 'Bahasa Melayu',
    prefix: 'BM',
    value: Language._BAHASA_MELAYU
  }
];

/// <summary>
/// Author : Saitama
/// </summary>
export class TippingType {
  static _CUSTOM = 1;
  static _PRESET = 2;
  static _TIPPING = 3;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class PaymentGatewayType {
  static _ONLINE_BANKING = 1;
  static _DUIT_NOW = 2;
  static _SUREPAY = 3;
}

/// <summary>
/// Author : Saitama
/// </summary>
export class StaffCategory {
  static _STAFF = 1;
  static _BAND = 2;
}
import { stringIsNullOrEmpty, isObjectEmpty } from "../../common/util";

export const _APP_BUSY = "appBusy";
export const _APP_FREE = "appFree";
export const _SHOW_CUSTOM_DIALOG = 'SHOW_CUSTOM_DIALOG';
export const _SET_TEMP_CUSTOM_DIALOG_DATA = 'SET_TEMP_CUSTOM_DIALOG_DATA';
export const _DISPOSE_CUSTOM_DIALOG = 'DISPOSE_CUSTOM_DIALOG';
export const _SET_BUTTON_CLICK_TIME = 'SET_BUTTON_CLICK_TIME';
export const _SHOW_LOGIN_DIALOG = 'SHOW_LOGIN_DIALOG';
export const _DISPOSE_LOGIN_DIALOG = 'DISPOSE_LOGIN_DIALOG';

/// <summary>
/// Author: Saitama
/// </summary>
export const setBusy = () => {
    return async dispatch => {
        dispatch({
            type: _APP_BUSY
        });
    }
}

/// <summary>
/// Author: Saitama
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _APP_FREE
        });
    }
}

/// <summary>
/// Author: Saitama
/// </summary>
export const showCustomDialog = ({
    success = true,
    headerTitle,
    title,
    content,
    customContent,
    onConfirm,
    onCancel,
    confirmTxt,
    cancelTxt,
    headerIcon,
    isManualHideAlert,
    horizontalBtn,
    disableHardwareBackPress = false,
    isPlainTheme = false,
    hideTitle = false,
    hideFooter = false
}) => {
    return async (dispatch, getState) => {
        const currentDialogData = getState().appState.customDialogData;

        dispatch({
            type: isObjectEmpty(currentDialogData) ? _SHOW_CUSTOM_DIALOG : _SET_TEMP_CUSTOM_DIALOG_DATA,
            data: {
                success: success,
                headerIcon: headerIcon,
                headerTitle: headerTitle,
                title: title,
                content: content,
                customContent: customContent,
                onConfirm: onConfirm,
                onCancel: onCancel,
                confirmTxt: confirmTxt,
                cancelTxt: cancelTxt,
                isManualHideAlert: isManualHideAlert,
                horizontalBtn: horizontalBtn,
                disableHardwareBackPress: disableHardwareBackPress,
                isPlainTheme: isPlainTheme,
                hideTitle: hideTitle,
                hideFooter: hideFooter
            }
        });
    }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const disposeCustomDialog = () => {
    return async dispatch => {
        dispatch({ type: _DISPOSE_CUSTOM_DIALOG });
    }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const setTempCustomDialogData = (data) => {
    return async dispatch => {
        dispatch({ type: _SET_TEMP_CUSTOM_DIALOG_DATA, data: data });
    }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const setButtonClickTime = (data) => {
    return async dispatch => {
        dispatch({ type: _SET_BUTTON_CLICK_TIME, data: data });
    }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const showLoginDialog = () => {
    return async dispatch => {
        dispatch({ type: _SHOW_LOGIN_DIALOG });
    }
};

/// <summary>
/// Author: Saitama
/// </summary>
export const disposeLoginDialog = () => {
    return async dispatch => {
        dispatch({ type: _DISPOSE_LOGIN_DIALOG });
    }
};
import React from "react";
import Home from "../pages/Home";
import Table from "../pages/Table";
import Message from "../pages/Message";
import MessageDetails from "../pages/MessageDetails";
import Tipping from "../pages/Tipping";
import PaymentGateway from "../pages/PaymentGateway";
import Staff from "../pages/Staff";
import Test from "../pages/Test";

/// <summary>
/// Author: Saitama
/// </summary>
export class Pages {
  static _DEFAULT = '*';
  static _TABLE = '/table';
  static _MESSAGE = '/message';
  static _MESSAGE_DETAILS = '/message-details';
  static _TIPPING = '/tipping-staff';
  static _PAYMENT_GATEWAY = '/payment-gateway';
  static _STAFF = '/staff';
  static _TEST = '/test';
}

/// <summary>
/// Author: Saitama
/// </summary>
export const _ROUTES = [
  {
    path: Pages._DEFAULT,
    title: "Home",
    auth: false,
    component: <Home />,
  },
  {
    path: Pages._TABLE,
    title: "Table",
    auth: false,
    component: <Table />,
  },
  {
    path: Pages._MESSAGE,
    title: "Message",
    auth: false,
    backButton: true,
    component: <Message />,
  },
  {
    path: Pages._MESSAGE_DETAILS,
    title: "Message Details",
    auth: false,
    backButton: true,
    component: <MessageDetails />,
  },
  {
    path: Pages._TIPPING,
    title: "Tipping",
    auth: false,
    backButton: true,
    component: <Tipping />,
  },
  {
    path: Pages._PAYMENT_GATEWAY,
    title: "Payment",
    auth: false,
    backButton: true,
    component: <PaymentGateway />,
  },
  {
    path: Pages._STAFF,
    title: "Tipping Staff",
    auth: false,
    backButton: true,
    component: <Staff />,
  },
  {
    path: Pages._TEST,
    title: "Test",
    auth: false,
    backButton: false,
    component: <Test />,
  },
];


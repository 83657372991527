import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery, stringIsNullOrEmpty, isObjectEmpty } from "../common/util";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import { useRouter } from '../hooks/useRouter';
import { Stack, Text, Box, Image } from '@chakra-ui/react';
import { LocalStorageKey, TippingType, ApiKey } from "../common/constant";
import { Pages } from "../routes/page";
import ApiEngine from "../common/api_engine";
import Routes from "../common/api_routes";
import { showCustomDialog } from "../application/action/app_action";
import OrderConfirmationDialog from "../component/order-confirmation-dialog";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    var _queryStr = useQuery(_location);
    const { t } = useTranslation();
    const [staffCategory, setStaffCategory] = useState([]);
    const [staff, setStaff] = useState([]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._BRANCH)) || stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._TABLE))) {
            _router.back();
        }
        else {
            getStaffCategory();
        }
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getStaffCategory() {
        try {
            var responseJson = await ApiEngine.get(Routes._STAFF_CATEGORY);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setStaffCategory(responseJson[ApiKey._API_DATA_KEY]);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getStaff(categoryId) {
        try {
            var responseJson = await ApiEngine.get(`${Routes._STAFF}?category=${categoryId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
            else if (responseJson[ApiKey._API_DATA_KEY].length == 0) {
                throw `${staffCategory.find(sc => sc.id == categoryId).name} temporary not available `
            }

            setStaff(responseJson[ApiKey._API_DATA_KEY]);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <Stack spacing={10} w='full'>
            {
                staffCategory.map((item, index) => {
                    return (
                        <Box key={index} p={5} bg='white' borderRadius={20}
                            onClick={() => {
                                if (!item.hasSub) {
                                    getStaff(item.id);
                                }
                                else {
                                    _router.navigate(Pages._STAFF, { categoryId: item.id });
                                }
                            }}>
                            <Stack align='center'>
                                <Text fontSize={'lg'} as='b'>{t(item.name)}</Text>
                            </Stack>
                        </Box>
                    )
                })
            }
            <OrderConfirmationDialog
                isVisible={staff.length > 0}
                onDialogClose={() => setStaff([])}
                title={'TIPPING'}
                type={TippingType._TIPPING}
                data={staff}
            />
        </Stack>
    )
}
import {
    ApiKey,
    _WEB_VERSION,
    SessionKey
} from '../../common/constant';
import Routes from '../../common/api_routes';
import { showCustomDialog } from './app_action';
import ApiEngine from '../../common/api_engine';
import i18n from '../../localization/i18n';
import {
    createFormBody,
    isObjectEmpty,
    stringIsNullOrEmpty
} from '../../common/util';
import { Pages } from '../../routes/page';
import axios from 'axios';
import { AuthActionTypes } from '../reducer/auth_reducer';
import { v1 as uuidv1 } from 'uuid';

/// <summary>
/// Author : Saitama
/// </summary>
export function performLogin(
    phone,
    password,
) {
    return async (dispatch, getState) => {
    };
}

/// <summary>
/// Author : Saitama
/// </summary>
export const performLogout = () => {
    return async (dispatch, getState) => {
    };
};

/// <summary>
/// Author : Saitama
/// </summary>
export function getMemberDetails(skipLoading = true) {
    return async (dispatch, getState) => {
    };
}

/// <summary>
/// Author: Saitama
/// </summary>
export function performEditProfile(data, successCallback) {
    return async (dispatch, getState) => {
    };
}

/// <summary>
/// Author: Saitama
/// </summary>
export const updateUserData = (userData) => {
    return async (dispatch) => {
    };
};

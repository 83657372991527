import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useRouter } from "../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../common/api_routes";
import { numberWithCurrencyFormat, isString, createFormBody, stringIsNullOrEmpty, isObjectEmpty } from "../common/util";
import { Pages } from "../routes/page";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Spinner, Flex } from "@chakra-ui/react";
import { PaymentGatewayType } from "../common/constant";
var md5 = require('md5');

/// <summary>
/// Author: CK
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const _PARAMS = _location?.state;
    const transaction = _PARAMS?.paymentGatewayTransaction;
    const _IS_DUITNOW = _PARAMS?.isDuitNow;
    const _PAYMENT_GATEWAY_TYPE_ID = _PARAMS?.paymentGatewayTypeId;
    const [proceedPayment, setProceedPayment] = useState(false);
    // console.log(transaction, _PAYMENT_GATEWAY_TYPE_ID);
    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (transaction) {
            setProceedPayment(true);
        }
        else {
            _router.navigate(Pages._DEFAULT);
        }

        return () => {

        };
    }, []);

    useEffect(() => {
        if (proceedPayment) {
            document.forms["paymentForm"].submit();
        }
    }, [proceedPayment]);

    return (
        <>
            <div>
                {
                    (proceedPayment && !isObjectEmpty(transaction)) &&
                    (
                        <div>
                            {
                                _PAYMENT_GATEWAY_TYPE_ID == PaymentGatewayType._ONLINE_BANKING ?
                                    <form name="paymentForm" method="POST" action={(!stringIsNullOrEmpty(transaction.baseUrl) ? transaction.baseUrl : Routes._GPAY88_CREATE_TRANSACTION) + '/Api/MakePayment'}>
                                        <input type="hidden" name="Code" value={transaction.apiCode} />
                                        <input type="hidden" name="Secret" value={transaction.apiSecret} />
                                        <input type="hidden" name="Signature" value={md5(transaction.apiCode + transaction.apiSecret + transaction.transactionId).toString().toUpperCase()} />
                                        <input type="hidden" name="RefNo" value={transaction.transactionId} />
                                        <input type="hidden" name="Amount" value={transaction.amount} />
                                        <input type="hidden" name="CallbackUrl" value={transaction.callbackUrl} />
                                        <input type="hidden" name="BackendUrl" value={transaction.responseUrl} />
                                        <input type="hidden" name="IpAddress" value={transaction.ipAddress} />
                                    </form>
                                    :
                                    _PAYMENT_GATEWAY_TYPE_ID == PaymentGatewayType._SUREPAY ?
                                        <form name="paymentForm" method="POST" action={transaction.baseUrl + '/fundtransfer'}>
                                            <input type="hidden" name="merchant" value={transaction.apiCode} />
                                            <input type="hidden" name="customer" value={transaction.customer} />
                                            <input type="hidden" name="token" value={transaction.apiSecret} />
                                            <input type="hidden" name="refid" value={transaction.transactionId} />
                                            <input type="hidden" name="amount" value={transaction.amount.toFixed(2)} />
                                            <input type="hidden" name="currency" value={"MYR"} />
                                            <input type="hidden" name="contractname" value={"PeaceClubQR"} />
                                            <input type="hidden" name="return_url" value={transaction.successCallbackUrl} />
                                            <input type="hidden" name="failed_return_url" value={transaction.failedCallbackUrl} />
                                            <input type="hidden" name="post_url" value={transaction.responseUrl} />
                                            <input type="hidden" name="clientip" value={transaction.ipAddress} />
                                        </form>
                                        :
                                        <form name="paymentForm" method="POST" action={`${transaction.baseUrl}/transaction/checkout`}>
                                            <input type="hidden" name="ClientCode" value={`${transaction.apiCode}`} />
                                            <input type="hidden" name="Signature" value={`${transaction.apiSecret}`} />
                                            <input type="hidden" name="TransactionId" value={`${transaction.transactionId}`} />
                                            <input type="hidden" name="Amount" value={`${transaction.amount.toFixed(2)}`} />
                                            <input type="hidden" name="Mode" value={`2`} />
                                            <input type="hidden" name="CallbackUrl" value={`${transaction.callbackUrl}`} />
                                            <input type="hidden" name="RedirectUrl" value={`${transaction.responseUrl}`} />
                                        </form>
                            }
                        </div>
                    )
                }
                <Flex direction='row' align={'center'} mt='10px'>
                    <Text fontSize='md'>Loading for redirection...</Text>
                    <Spinner size='sm' color="#78efa8" ml='10px' />
                </Flex>
            </div>
        </>
    )
}
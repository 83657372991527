import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery, stringIsNullOrEmpty } from "../common/util";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import { useRouter } from '../hooks/useRouter';
import { Stack, Text, Box, Image, Flex, Spacer, Button } from '@chakra-ui/react';
import { Pages } from "../routes/page";
import { LocalStorageKey } from "../common/constant";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    var _queryStr = useQuery(_location);
    const { t } = useTranslation();

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if ((stringIsNullOrEmpty(_queryStr?.get('branch')) || stringIsNullOrEmpty(_queryStr?.get('table'))) &&
            (stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._BRANCH)) || stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._TABLE)))) {
            _router.back();
        }
    }, [])

    return (
        <Stack spacing={10} w='full'>
            <Button
                leftIcon={<Image boxSize={'25px'} src={require('../assets/images/king.png')} />}
                bg='black'
                color='white'
                variant='solid'
                onClick={() => window.open('https://www.peaceclubpg.com/vertical-monthly-top-receiver', '_blank').focus()}>
                {t('TOP_RECEIVER')}
            </Button>
            <Flex gap={2}>
                <Button 
                    leftIcon={<Image boxSize={'30px'} src={require('../assets/images/monthly.png')} />}
                    colorScheme='pink'
                    variant='solid'
                    style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        paddingBlock: '30px'
                    }}
                    onClick={() => window.open('https://www.peaceclubpg.com/vertical-monthly-top-spender', '_blank').focus()}>
                    {t('MONTHLY_SPENDER')}
                </Button>
                <Spacer />
                <Button
                    rightIcon={<Image boxSize={'30px'} src={require('../assets/images/lifetime.png')} />}
                    colorScheme='pink'
                    variant='solid'
                    style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        paddingBlock: '30px'
                    }}
                    onClick={() => window.open('https://www.peaceclubpg.com/vertical-lifetime-top-spender', '_blank').focus()}>
                    {t('LIFETIME_SPENDER')}
                </Button>
            </Flex>
            <Box p={10} bg='white' borderRadius={20} onClick={() => { _router.navigate(Pages._MESSAGE); }}>
                <Stack align='center'>
                    <Image boxSize={'70px'} src={require('../assets/images/message.png')} />
                    <Text fontSize={'lg'} as='b'>{t('MESSAGE')}</Text>
                </Stack>
            </Box>
            <Box p={10} bg='white' borderRadius={20} onClick={() => { _router.navigate(Pages._TIPPING); }}>
                <Stack align='center'>
                    <Image boxSize={'70px'} src={require('../assets/images/tip.png')} />
                    <Text fontSize={'lg'} as='b'>{t('TIPPING')}</Text>
                </Stack>
            </Box>
        </Stack>
    )
}
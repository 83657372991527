import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { _ROUTES, Pages } from "./page";

/// <summary>
/// Author: Saitama
/// </summary>
const Content = () => {
  var _currentLocation = useLocation();

  /// <summary>
  /// Author: Saitama
  /// </summary>
  useEffect(() => {
    if (_currentLocation) {
      window.scrollTo(0, 0);

      var currentRoute = _ROUTES.find(
        (route) => _currentLocation.pathname == route.path
      );

      if (currentRoute == null) {
        window.history.pushState({}, document.title, Pages._DEFAULT);
      }
    }
  }, [_currentLocation]);

  return (
    <Routes location={_currentLocation}>
      {_ROUTES.map((route, index) => (
        <Route key={index} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps, null)(Content);

import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery, stringIsNullOrEmpty, isObjectEmpty } from "../common/util";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import { useRouter } from '../hooks/useRouter';
import { Stack, Text, Box, Center } from '@chakra-ui/react';
import { LocalStorageKey, ApiKey, TippingType } from "../common/constant";
import ApiEngine from "../common/api_engine";
import Routes from "../common/api_routes";
import { showCustomDialog } from "../application/action/app_action";
import OrderConfirmationDialog from "../component/order-confirmation-dialog";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    var _queryStr = useQuery(_location);
    const { t } = useTranslation();
    const [messageTemplate, setMessageTemplate] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState({});

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._BRANCH)) || stringIsNullOrEmpty(localStorage.getItem(LocalStorageKey._TABLE))) {
            _router.back();
        }
        else {
            getMessageTemplate();
        }
    }, [])

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getMessageTemplate() {
        try {
            var responseJson = await ApiEngine.get(Routes._MESSAGE_TEMPLATE);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setMessageTemplate(responseJson[ApiKey._API_DATA_KEY]);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <Stack spacing={10} w='full'>
            {
                messageTemplate.map((item, index) => {
                    return (
                        <Box key={index} bg={'whiteAlpha.800'} p={3} borderRadius={5} onClick={() => setSelectedMessage(item)}>
                            <Center>
                                <Text fontWeight={'bold'}>{item.content}</Text>
                            </Center>
                        </Box>
                    )
                })
            }
            <OrderConfirmationDialog
                isVisible={!isObjectEmpty(selectedMessage)}
                onDialogClose={() => setSelectedMessage({})}
                title={'PRESET_MESSAGE'}
                type={TippingType._PRESET}
                data={selectedMessage}
            />
        </Stack>
    )
}